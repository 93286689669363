<template>
  <div class="navbar">
    <div class="nav">
      <nav-item
        v-for="(navItem, index) in navItems"
        :key="index"
        :id="index"
        :name="navItem.name"
        :active="navItem.active"
        @click="setActive"
      />
    </div>
  </div>
</template>

<script>
import NavItem from "./NavItem";

export default {
  components: {
    NavItem,
  },
  data() {
    return {
      navItems: [
        {
          name: "Portfolio",
          routeName: "portfolio",
          active: true,
        },
        {
          name: "Sobre mi",
          routeName: "about",
          active: false,
        },
        {
          name: "Contacto",
          routeName: "contacts",
          active: false,
        },
      ],
    };
  },
  methods: {
    setActive(pos) {
      this.navItems.map((navItem, index) => {
        navItem.active = pos === index;
      });

      this.$router.push({ name: this.navItems[pos].routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .nav {
    height: 60px;
    background-color: #ff3b19;
    width: 30%;
    border-radius: 50px 10px 50px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2% 0 2%;
  }
}

@media (max-width: 1100px) {
  .navbar {
    .nav {
      width: 100%;
    }
  }
}
</style>
