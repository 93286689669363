<template>
  <div class="presentation">
    <div class="name">
      <image-cv />
      <name />
      <profession />
      <social />
    </div>
    <div class="details">
      <info icon="el-icon-date" text="Abril 03, 1967" />
      <info icon="el-icon-location-outline" text="Barcelona, España" />
      <info icon="el-icon-message" text="andrea@andreathome.es" />
      <info icon="el-icon-mobile-phone" text="(+34) 666 10 79 54" />
      <btn-download />
    </div>
  </div>
</template>

<script>
import ImageCv from "@/components/presentation/name/Image";
import Name from "@/components/presentation/name/Title";
import Profession from "@/components/presentation/name/Profession";
import Social from "@/components/presentation/name/Social";
import Info from "@/components/presentation/details/Info";
import BtnDownload from "@/components/presentation/details/BtnDownload";

export default {
  name: "Andrea",
  components: {
    ImageCv,
    Name,
    Profession,
    Social,
    Info,
    BtnDownload,
  },
};
</script>

<style lang="scss" scoped>
.presentation {
  width: 26%;
  padding: 0 2%;

  .name {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-top-left-radius: 7%;
    border-top-right-radius: 7%;
    background-color: white;
    padding: 0 5%;
  }

  .details {
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    background-color: #f5f8f9;
    padding: 2rem 15% 2rem 15%;
    color: #44566c;
  }
}

@media (max-width: 1100px) {
  .presentation {
    width: 100%;
    margin-top: 10em;
  }
}
</style>
