import Vue from "vue";
import Router from "vue-router";
import { lazy } from "./utils";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      name: "portfolio",
      component: () => lazy(import("@/pages/Portfolio")),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/about",
      name: "about",
      component: () => lazy(import("@/pages/About")),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => lazy(import("@/pages/Contacts")),
      meta: {
        requiresAuth: false,
      },
    },
  ],
});

export default router;
