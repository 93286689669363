<template>
  <div class="layout">
    <navbar />
    <main>
      <presentation />
      <contents />
    </main>
  </div>
</template>

<script>
import navbar from "@/components/navbar/Navbar.vue";
import Presentation from "@/components/presentation/Presentation.vue";
import Contents from "@/components/contents/Contents.vue";

export default {
  name: "App",
  components: {
    navbar,
    Presentation,
    Contents,
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400:300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

.layout {
  padding: 5% 3%;

  main {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1100px) {
    main {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
