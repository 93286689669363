<template>
  <div id="app">
      <!-- <ConstructionTemplate /> -->
    <layout />
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
//import ConstructionTemplate from "@/components/ConstructionTemplate.vue";

export default {
  name: "App",
  components: {
    //ConstructionTemplate,
    Layout,
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Archivo", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.65;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f6;
  color: #44566c;
}
</style>
